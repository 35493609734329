import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Banner from '../NewTheme/Ui/Banner'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { ParallaxProvider, useParallax } from 'react-scroll-parallax'

const getData = () => {
  const data = useStaticQuery(graphql`
    query {
        faqImage: file(relativePath: { eq: "infos/faq.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        transportImage: file(relativePath: { eq: "infos/transport.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        hebergementImage: file(relativePath: { eq: "infos/hebergement.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        ptitParvisImage: file(relativePath: { eq: "infos/ptitparvis.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        entrevuesImage: file(relativePath: { eq: "infos/entrevues.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        carteImage: file(relativePath: { eq: "infos/carte.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        parvisImage: file(relativePath: { eq: "infos/parvis.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        ruefestiveImage: file(relativePath: { eq: "infos/ruefestive.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        zoneDeServicesImage: file(relativePath: { eq: "infos/zonedeservices.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        imprevisiblesImage: file(relativePath: { eq: "infos/imprevisibles.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        familleImage: file(relativePath: { eq: "infos/famille.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        zonedereconfortImage: file(relativePath: { eq: "infos/zonedereconfort.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        scenesImage: file(relativePath: { eq: "infos/scenes.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        menuImage: file(relativePath: { eq: "infos/menu.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
    }
  `)
  return data
}

const InfosNavigation = () => {
  const data = getData()
  const faqImage = getImage(data.faqImage)
  const transportImage = getImage(data.transportImage)
  const hebergementImage = getImage(data.hebergementImage)
  const ptitParvisImage = getImage(data.ptitParvisImage)
  const entrevuesImage = getImage(data.entrevuesImage)
  const carteImage = getImage(data.carteImage)
  const parvisImage = getImage(data.parvisImage)
  const ruefestiveImage = getImage(data.ruefestiveImage)
  const zoneDeServicesImage = getImage(data.zoneDeServicesImage)
  const imprevisiblesImage = getImage(data.imprevisiblesImage)
  const zonedereconfortImage = getImage(data.zonedereconfortImage)
  const scenesImage = getImage(data.scenesImage)

  const NavigationComponent = () => {
    const { ref: parallaxRef } = useParallax({
      speed: -4
    })

    colorIndex = 0;
    return (
        <nav ref={parallaxRef} className={'pb-12'}>
            <ul className={'flex justify-center items-center flex-wrap max-w-[1200px] mx-auto'}>
                { NavigationButton('Faq', '/infos/faq#info_content', faqImage) }
                { NavigationButton('Transport', '/infos/transports#info_content', transportImage) }
                { NavigationButton('Hébergement', '/infos/hebergements#info_content', hebergementImage) }
                { NavigationButton('Parvis', '/infos/parvisdufestif#info_content', parvisImage) }
                { NavigationButton('Carte', '/infos/carte#info_content', carteImage) }
                { NavigationButton("P'tit Parvis", '/infos/ptitparvis#info_content', ptitParvisImage) }
                { NavigationButton('Les arts de la rue Télé-Québec', '/infos/ruefestive#info_content', ruefestiveImage, true) }
                { NavigationButton('Zone de services', '/infos/zonedeservices#info_content', zoneDeServicesImage) }
                { NavigationButton('Spectacles Imprévisibles Loto-Québec', '/infos/imprevisibles#info_content', imprevisiblesImage, true) }
                { NavigationButton('Entrevues', '/infos/entrevues#info_content', entrevuesImage) }
                { FamilleButton() }
                { NavigationButton('Zone de réconfort', '/infos/zonedereconfort#info_content', zonedereconfortImage) }
                { NavigationButton('Les Scènes', '/infos/scenes#info_content', scenesImage) }
                { MenuButton() }
            </ul>
        </nav>
    )
  }
  return (
        <div>
            <Banner title="Infos" />
            <ParallaxProvider>
                <NavigationComponent />
            </ParallaxProvider>
        </div>
  )
}

let colorIndex = 0
const NavigationButton = (title, internalLink, imageSrc, longText = false, color = undefined) => {

  const getNextColor = () => {
    const bgArray = ['bg-yellow-wolf2025', 'bg-blue-wolf2025', 'bg-pink-wolf2025']
    return bgArray[(colorIndex++) % bgArray.length]
  }

  return (
          <li className={`infos-menu-icon w-[150px] h-[150px] rounded-3xl m-[10px] ${color ? color.color : getNextColor()}`}>
              <a rel="noopener noreferrer" href={internalLink}>
                  <div className="m-[15px] !text-center !align-center">
                      <div>
                          <GatsbyImage image={imageSrc} alt={"Logo " + title} className={`w-[80px] ${longText ? 'h-[70px]' : 'h-[80px]'}`} imgStyle={{ objectFit: 'contain' }}/>
                      </div>
                      <div className={`font-obviously font-semibold tracking-[-1px] text-md uppercase text-purple-wolf2025 inline-block relative leading-5 ${longText ? 'h-[54px]' : 'h-[24px] mt-[5px]'}`}>
                          {title}
                      </div>
                  </div>
              </a>
          </li>
  )
}

const FamilleButton = () => {
  const data = getData()
  return (
    <>
      { NavigationButton('Activités familiales', '/infos/famille#info_content', getImage(data.familleImage)) }
    </>
  )
}
const MenuButton = (color) => {
  const data = getData()
  return (
    <>
      { NavigationButton('Menu', '/infos/menu#info_content', getImage(data.menuImage), false, color) }
    </>
  )
}

export default InfosNavigation
export { FamilleButton, MenuButton }
